import RoutesWrapper from "routes";
import "assets/styles/index.scss";

function App() {

  return <>
    <RoutesWrapper />
  </>;
}

export default App;
